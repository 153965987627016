import Vue from 'vue'
import Router from 'vue-router'
import index from './../pages/index'
import about from './../pages/about'
import product from './../pages/product'
import support from './../pages/support'
import our from './../pages/our'
Vue.use(Router);

const routes = [
    {
        path:'/',
        name:'index',
        component:index,
        meta:{title:'首页'}
    },
    {
        path:'/about',
        name:'about',
        component:about,
        meta:{title:'关于我们'}
    },
    {
        path:'/product',
        name:'product',
        component:product,
        meta:{title:'产品展示'}
    } , 
    {
        path:'/support',
        name:'support',
        component:support,
        meta:{title:'技术支持'}
    } , 
    {
        path:'/our',
        name:'our',
        component:our,
        meta:{title:'联系我们'}
    }  

]
const router = new Router({
    mode:"history",//去除哈希值的#号
    routes
})
export default router;
