<!--公司首页-->
<template>
    <div>
        <!-- 1、页面顶部 -->
        <header id="top">            
            <div class="company">
                <img class='company-logo' src="../assets/img/company-logo.png" alt="萤火虫智能科技（东莞）有限公司">
                <span class="company-name">
            萤火虫智能科技（东莞）有限公司
            </span>
            </div>
            <div class="phone">
                
                <span class="phone-logo">
                   <img src="../assets/img/phone.jpg">
                </span>
                <ul>
                    <li class="hot-phone">热线电话</li>
                    <li class="phone-number">189-3653-5625</li>
                </ul>
            </div>
        </header>
        <!-- 2、导航栏 -->
        <nav id="nav">
            <ul>
                <li><a href="/" class="active">首页</a></li>
                <li><a href="about">关于我们</a></li>
                <li><a href="product">产品展示</a></li>
                <li><a href="support">技术支持</a></li>
                <li><a href="our">联系我们</a></li>
            </ul>
        </nav>
        <!-- 3、Banner部分 -->
        <div id="banner">
            <!-- 1、商品分类信息 -->
            <ul id="cate-box">
                <li >
                   <h3> <a href="https://item.taobao.com/item.htm?spm=a21n57.1.0.0.2fec523ceZj1uQ&id=663508614514&ns=1&abbucket=19#detail">淘宝店链接</a></h3>
                </li>
                <li>
                    <a href="#">陪护床共享锁</a>
                </li>
                <li>
                    <a href="#">童车共享锁</a>
                </li>
                <li>
                    <a href="#">共享门锁</a>
                </li>
                <li>
                    <a href="#">收费模式共享锁</a>
                </li>
                <li>
                    <a href="#">非收费式信息共享锁</a>
                </li>
                <li>
                    <a href="#">充电共享开关</a>
                </li>
                <li>
                    <a href="#">芯片模组</a>
                </li>
            </ul>
            <!-- 2、banner 滑动广告 -->
            <div id="slider">
                <img src="../assets//img/banner_01.jpg">
                <!-- 列表 -->
                <ul>
                    <li></li>
                    <li></li>
                </ul>
            </div>

        </div>
        <!-- 4、创新产品展示 -->
        <div id="new-products">

   
         <form method="post" action="https://firefly-ai.cn/message.php"> 
                <label><h2>用户留言</h2></label><br/><br>
                <label for="name">称呼：</label>
                <input type="text" id="name" name="name" value=""><br><br>
                <label for="phone">电话：</label>
                <input type="text" id="phone" name="phone" value=""><br><br><br>
                <label for="comment">留言：</label>
                <textarea id="comment" name="comment" rows="20" cols="80"></textarea><br><br>
                <input type="submit" value="提交">
            </form> 
            
            
        </div>
        <!-- 5、新闻中心 -->
       
        <!-- 6、产品展示 -->
        
        <!-- 7、相关文章 -->
        
        <!-- 8、首页底部 -->
        <footer>
            <div id="footerContent">
                <ul class="footerMain">
                    <li>
                        <img src="../assets/img/address_logo.png">
                        <span>地址：广东省东莞市松山湖园区新城路9号6栋105室</span>
                    </li>
                    <li>
                        <img src="../assets/img/telephone_logo.png">
                        <span>联系电话：189-3653-5625</span>
                    </li>
                    <li>
                        <img src="../assets/img/email_logo.png">
                        <span>邮箱：190768409@qq.com</span>
                    </li>
                </ul>
                <div class="footerInfo">
                    ©2020 - 萤火虫智能科技（东莞）有限公司 版权所有
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
    export default {
        name: "our",
        data() {
            return {}
        },
        mounted() {
        },
        methods: {}
    }
</script>

<style scoped>
    /************整体布局*************/
    #top, #nav > ul, #banner, #new-products, #news-center, #product-display, #article, #footerContent {
        width: 1200px;
        margin: 0 auto;
    }

    img.animation {
        transition: all .4s;
    }

    img.animation:hover {
        transform: scale(1.3);
        cursor: pointer;
    }

    .dot {
        background: #ff6637;
        width: 6px;
        height: 9px;
        margin-left: 16px;
    }

    .line {
        flex: 1;
        height: 9px;
        margin-left: 14px;
        background: #f5f5f5;
    }

    /**********1、页面顶部模块 - #top************/
    #top {
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    #top .company {
        display: flex;
        align-items: center
    }

    #top .company .company-logo {
        width: 50px;
        height: 40px;
    }

    #top .company .company-name {
        font-size: 32px;
        color: #333333;
        font-weight: bold;
        margin-left: 10px;
    }

    #top .phone {
        display: flex;
    }

    #top .phone .phone-logo {
        margin-right: 20px;
    }

    #top .phone .hot-phone {
        font-size: 16px;
        color: rgb(102, 102, 102);
    }

    #top .phone .phone-number {
        font-size: 24px;
        color: rgb(68, 68, 68);
    }

    /**********页面顶部模块结束 - #top************/
    /********** 2、导航栏 - #nav*********/
    #nav {
        height: 50px;
        line-height: 50px;
        background-color: rgb(43, 43, 43);
    }

    #nav ul {
        width: 1200px;
        margin: 0 auto;
        display: flex;
    }

    #nav li > a {
        display: inline-block;
        padding: 0 20px;
        height: 50px;
        text-decoration: none;
        font-size: 16px;
        color: #ffffff;
        margin-right: 30px;
    }

    #nav li > a.active {
        background-color: #ff0700;
    }

    #nav li > a:hover {
        background-color: #ff0700;
    }

    /********** 导航栏结束 - #nav*********/
    /********** 3、banner **********/
    #banner {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    }

    #cate-box {
        width: 210px;
        height: 400px;
        box-sizing: border-box;
        padding: 5px 10px;
        background-color: #f7f7f7;
    }

    #cate-box > li > a {
        display: block;
        width: 190px;
        height: 50px;
        line-height: 50px;
        font-size: 14px;
        text-decoration: none;
        color: #4d4d4d;
    }

    #slider {
        width: 960px;
        height: 400px;
        position: relative;

    }

    #slider ul {
        display: flex;
        position: absolute;
        bottom: 10px;
        right: 450px;
    }

    #slider li {
        width: 8px;
        height: 8px;
        background-color: #ffffff;
        border-radius: 50%;
        margin-right: 10px;
    }

    #slider li:hover {
        background-color: #c2c2c2;
        cursor: pointer;
    }

    /********** banner结束 **********/
    /********** 4、创新产品展示 **********/
    #new-products {
        margin: 50px auto;
        display: flex;
    }

    /********** 创新产品展示结束 **********/
    /********** 5、新闻中心 **********/
    #news-center {
        margin: 90px auto;
    }

    #news-center .news-top {
        padding: 20px 0;
        display: flex;
        align-items: center;
    }

    .news-top .title-text1 {
        font-size: 24px;
        font-weight: bold;
        color: #2b2b2b;
    }

    .news-top .title-text2 {
        font-size: 18px;
        color: #dbdbdb;
        margin-left: 15px;
        margin-top: 2px;

    }

    .news-main {
        margin-top: 40px;
        display: flex;
    }

    .news-picture {
        margin: 10px 17px 12px 0;
        width: 420px;
        height: 300px;
        overflow: hidden;
    }

    .news-picture > img {
        width: 420px;
        height: 300px;
    }

    .news-main > .news {
        width: 450px;
        box-sizing: border-box;
        padding: 20px 50px 0 0;
    }

    .news h2 {
        width: 73%;
        font-weight: normal;
    }

    .news a {
        font-size: 16px;
    }

    .news p {
        font-size: 14px;
        color: #888888;
        line-height: 24px;
        margin-top: 4px;
        margin-bottom: 6px;
    }

    .hot-news-top {
        width: 313px;
        height: 26px;
        margin: 20px 0;
        display: flex;
        align-items: center;
    }

    .hot-news-top .title-text1 {
        font-size: 16px;
        font-weight: bold;
        color: #000000;
    }

    .hot-pictures {
        display: flex;
    }

    .hot-pictures li {
        margin: 5px 8px 20px 8px;
        width: 131px;
        height: 93px;
        overflow: hidden;
    }

    .hot-pictures img {
        width: 131px;
        height: 93px;
    }

    .hot-news > p {
        padding: 10px 0;
    }

    .hot-news > p > a {
        font-size: 16px;
    }

    /********** 新闻中心结束 **********/
    /********** 6、产品展示 **********/
    .displayBg {
        background: #f5f5f5;
    }

    #product-display {
        padding: 80px 0;
    }

    .product-top {
        padding: 20px 0;
    }

    .product-top > .title-text1 {
        font-size: 30px;
        color: #2b2b2b;
    }

    .product-top > .title-text2 {
        font-size: 18px;
        color: #999999;
        margin-left: 15px;
    }

    .product-table {
        width: 1200px;
        height: 527px;
        margin-top: 30px;
        border-collapse: collapse;
        font-size: 0;
    }

    .product-table td {
        padding: 0;
    }

    .product-table img {
        width: 280px;
        height: 250px;
    }

    .product-table > tr:nth-child(1) > td:last-child img {
        width: 311px;
        height: 527px;
    }

    .product-table > tr:nth-child(1) > td {
        vertical-align: top;
    }

    .product-table > tr:nth-child(2) > td {
        vertical-align: bottom;
    }

    .product-table > tr:nth-child(1) > td:last-child {
        text-align: right;
    }

    .product-table td > a {
        display: inline-block;
        overflow: hidden;
        width: 280px;
        height: 250px;
    }

    .product-table > tr:nth-child(1) > td:last-child > a {
        width: 311px;
        height: 527px;
    }

    /********** 产品展示结束 **********/
    /********** 7、相关文章 **********/
    #article {
        margin-top: 80px;
        margin-bottom: 80px;
    }

    #article > ul {
        display: flex;
        justify-content: space-between;
    }

    #article > ul > li {
        width: 290px;
    }

    #article li p.post-title {
        font-size: 16px;
        font-weight: bold;
        color: #2b2b2b;
        height: 46px;
        line-height: 46px;
    }

    #article .post-content {
        padding-left: 9px;
        width: 272px;
    }

    #article li img {
        height: 120px;
        margin-bottom: 20px;
    }

    .post-content > ul > li {
        padding-bottom: 10px;
    }

    .post-content > ul > li > a {
        font-size: 14px;
        color: #666666;
    }
    .post-content > ul > li > a > span{
        float: right;
    }
    /********** 相关文章结束 **********/
    /********** 8、首页底部 **********/
    footer{
        width: 100%;
        height: 221px;
        border-top: 2px solid #ff6637;
        background: rgb(43 43 43);
        font-size: 14px;
    }
    .footerMain{
        height:167px;
        color: rgb(170, 170, 170);
        display: flex;
        align-items: center;
    }
    .footerMain>li{
        width: 400px;
    }
    .footerMain>li:nth-child(2){
      padding-left: 80px;
    }
    .footerMain>li>img{
        vertical-align:middle;
        margin-right: 10px;
    }
    .footerInfo{
        text-align: center;
        color: #999999;
    }
    {
    #tao{
        
        
      
        font-size: 30px;
        color: #ffffff;
        
    }
    }

</style>
